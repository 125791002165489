import React, {useContext} from 'react';
import { Container, Row, Col } from "react-bootstrap";
import { MailchimpForm } from "./MailchimpForm";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Footer.css";

import logo from "../assets/imgLogo/logoBaobab.png";
import navIcon1 from "../assets/SocialIcons/nav-icon1.svg";
import navIcon2 from "../assets/SocialIcons/twitter.svg";
import navIcon3 from "../assets/SocialIcons/tiktok.svg";
import navIcon4 from "../assets/SocialIcons/mail2.svg";
import laGuilde from "../assets/imgLogo/laGuilde.png";
import CCMM_Membre from "../assets/imgLogo/CCMM_Membre.png";
import traduction from './traduction';
import {LanguageContext} from './contextLang'

export const Footer = () => {
  const { language } = useContext(LanguageContext);
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 768,
        settings: { slidesToShow: 1 },
      },
    ],
  };

  const companyLogos = [
    { src: laGuilde, alt: "La Guilde" },
    { src: logo, alt: "Logo Baobab" },
    { src: CCMM_Membre, alt: "CCMM Membre" },
  ];
  
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center mb-4">
          <Col xs={12}>
            <MailchimpForm />
          </Col>
        </Row>
        <h2 className="text-center mb-4">{traduction[language].Partenaires}</h2>
        <Slider {...sliderSettings} className="logo-slider">
          {companyLogos.map((logo, index) => (
            <div key={index} className="logo-item">
              <img src={logo.src} alt={logo.alt} />
            </div>
          ))}
        </Slider>
        <Row className="footer-content mt-4">
          <Col className="text-center">
            <div className="social-icon">
              <a href="https://www.linkedin.com/company/baobabgames/">
                <img src={navIcon1} alt="LinkedIn" />
              </a>
              <a href="https://twitter.com/_Baobab_Games">
                <img src={navIcon2} alt="Twitter" />
              </a>
              <a href="https://www.tiktok.com/@baobab_games">
                <img src={navIcon3} alt="TikTok" />
              </a>
              <a href="mailto:support@baobabgames.group">
                <img src={navIcon4} alt="Email" />
              </a>
              <p>{traduction[language].copyright}</p>
            </div>
          </Col>
        </Row>
        
       
      </Container>
    </footer>
  );
};
