import React, { useState,useContext} from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import '../EventSection.css'; // Your custom CSS
import traduction from './traduction';
import {LanguageContext} from './contextLang'

const EventSection = () => {
    // LinkedIn post URLs (or embed codes)


    // State to handle whether to show more posts or not
    const [showMore, setShowMore] = useState(false);
    const { language } = useContext(LanguageContext)
    const linkedInPosts = [
        {
            url: "https://www.linkedin.com/embed/feed/update/urn:li:share:7262154968013443072", // Replace with the actual embed URL
            title: traduction && traduction[language] ? traduction[language].Eventtitle1 : "Default Title",
        },
        {
            url: "https://www.linkedin.com/embed/feed/update/urn:li:share:7252688828233056257", // Replace with the actual embed URL
            title: traduction && traduction[language] ? traduction[language].Eventtitle2 : "Default Title",
            
        },

        // Add more LinkedIn post URLs or embed codes as needed
    ];
    // Function to toggle between showing more posts or fewer
    const toggleShowMore = () => {
        setShowMore(!showMore);
    };

    return (
        <section className="events" id="events">
        <Container className="my-5">
            <h1 className="text-center mb-4">{traduction[language].Eventtitle}</h1>
            <Row>
                {(showMore ? linkedInPosts : linkedInPosts.slice(0, 2)).map((post, index) => (
                    <Col key={index} md={5} className="mb-4">
                        <div className="linkedin-card">
                            <h4>{post.title}</h4>
                            <iframe
                                src={post.url}
                                width="100%"
                                height="auto"
                                frameBorder="0"
                                allowFullScreen
                                title={`LinkedIn Post ${index + 1}`}
                                className="linkedin-frame"
                            ></iframe>
                        </div>
                    </Col>
                ))}
                <div className="text-center">
                <a href="https://www.linkedin.com/company/baobabgames/" class="btn btn-primary" target="_blank" rel="noopener noreferrer">
                {traduction[language].EventButton}
</a>

            </div>
            </Row>
            
        </Container>
        </section>
    );
};

export default EventSection;
